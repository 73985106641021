import * as React from "react"

function ContactPhoneIcon(props) {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" {...props}>
      <path
        fill="#1C5BAE"
        stroke="#1C5BAE"
        d="M23.839 18.744l-3.485-2.435a2.691 2.691 0 00-3.746.666l-.808 1.16c-1.356-.91-2.87-2.18-4.311-3.622-1.442-1.442-2.712-2.954-3.62-4.31l1.158-.81c.59-.41.985-1.027 1.11-1.734a2.673 2.673 0 00-.444-2.011L7.26 2.163A2.697 2.697 0 005.062 1c-.3 0-.596.053-.876.157a4.822 4.822 0 00-.907.444l-.48.34a3.492 3.492 0 00-.34.302c-.587.587-1.004 1.33-1.239 2.208C.217 8.211 2.702 13.897 7.404 18.6c3.948 3.948 8.691 6.4 12.378 6.401 0 0 0 0 0 0 .632 0 1.227-.074 1.77-.219.878-.234 1.62-.65 2.208-1.238a3.79 3.79 0 00.317-.36l.34-.484c.165-.265.31-.561.429-.882.41-1.109-.004-2.373-1.007-3.073zm-.002 2.7c-.094.25-.205.481-.317.66l-.307.44a2.87 2.87 0 01-.214.239c-.452.45-1.032.774-1.725.96-.452.12-.954.181-1.492.181-3.412 0-7.864-2.332-11.618-6.086-4.37-4.37-6.797-9.76-5.904-13.109.185-.693.508-1.274.96-1.725.075-.076.153-.149.218-.2l.436-.306c.204-.129.435-.24.685-.332.16-.06.33-.09.503-.09.515 0 1.007.263 1.315.703L8.81 6.263a1.612 1.612 0 01-.399 2.248L6.826 9.618a.539.539 0 00-.148.728c.96 1.527 2.398 3.277 4.05 4.928 1.652 1.652 3.402 3.09 4.928 4.05a.54.54 0 00.728-.147l1.107-1.587c.49-.704 1.54-.893 2.248-.4l3.484 2.435c.602.42.854 1.169.614 1.818z"
      />
    </svg>
  )
}

export default ContactPhoneIcon