import * as React from 'react';

function CarIcon(props) {
  const color = props.color || '#1C5BAE';
  return (
    <svg width={48} height={20} viewBox="0 0 48 20" {...props}>
      <path
        fill={color}
        d="M.683 4.782l5.406-.772a27.32 27.32 0 013.063-1.623A26.811 26.811 0 0120.239 0h.939a27.06 27.06 0 0112.916 3.293l2.728 1.489c3.193.035 6.325.885 9.1 2.466a3.634 3.634 0 011.825 3.146v4.724c0 .44-.356.796-.796.796H44.28a4.757 4.757 0 01-8.978 0H14.039a4.757 4.757 0 01-8.979 0h-.285a.81.81 0 01-.296-.056L1.9 14.823A3.009 3.009 0 010 12.015V5.57c0-.396.291-.731.683-.788zM36.67 14.96a3.183 3.183 0 106.237-1.273 3.183 3.183 0 00-6.237 1.273zm-14.389-.636h12.733a4.773 4.773 0 014.774-4.774 4.774 4.774 0 014.775 4.774h1.592v-3.93a2.036 2.036 0 00-1.024-1.763 17.206 17.206 0 00-8.526-2.264H22.282v7.957zm0-9.549h11.203l-.153-.083a25.44 25.44 0 00-11.05-3.065v3.148zM20.69 1.592h-.45a25.208 25.208 0 00-9.364 1.794l1.39 1.39h8.424V1.59zM6.43 14.96a3.183 3.183 0 106.237-1.273A3.183 3.183 0 006.43 14.96zM1.59 7.161h2.39v1.592H1.592v3.262a1.425 1.425 0 00.9 1.33l2.283.916c0-.073.01-.145.014-.218.005-.074.007-.16.014-.24.008-.079.024-.158.037-.238.013-.08.022-.159.038-.231.016-.073.04-.153.06-.229.02-.075.037-.152.06-.226.024-.074.053-.145.08-.217.027-.072.052-.147.08-.219.027-.071.065-.137.098-.204.034-.068.066-.142.104-.21.037-.069.08-.127.115-.19a2.64 2.64 0 01.125-.202c.046-.065.088-.117.131-.176.044-.059.094-.128.145-.189.05-.061.098-.11.147-.16.05-.05.105-.117.16-.173.055-.055.109-.1.163-.148.055-.05.114-.108.175-.16.06-.051.122-.091.182-.136.06-.046.121-.094.184-.137.064-.042.136-.079.204-.124.069-.044.124-.08.189-.113.064-.035.145-.07.218-.106.073-.035.13-.066.198-.094.068-.029.16-.06.239-.088.08-.028.131-.05.199-.072.09-.028.183-.048.276-.071.06-.014.118-.033.18-.046.097-.02.198-.031.297-.046.06-.007.116-.02.174-.026a4.779 4.779 0 015.261 4.75h6.368V6.365h-8.753a.793.793 0 01-.563-.233L9.311 4.07c-.866.412-1.709.873-2.523 1.38a.811.811 0 01-.309.113l-4.887.698v.901z"
      />
    </svg>
  );
}

export default CarIcon;
