import * as React from "react"

function ViewIcon(props) {
  return (
    <svg width={32} height={20} viewBox="0 0 32 20" {...props}>
      <g fill="#1C5BAE">
        <path d="M31.034 8.966C29.344 6.862 23.31 0 15.862 0S2.38 6.862.69 8.966c-.483.586-.483 1.448 0 2.068C2.38 13.138 8.414 20 15.862 20s13.483-6.862 15.172-8.966c.449-.586.449-1.482 0-2.068zM15.862 17.93c-6.345 0-11.828-6.138-13.345-7.931 1.483-1.828 7-7.931 13.345-7.931 6.345 0 11.828 6.103 13.345 7.931-1.517 1.828-7 7.931-13.345 7.931z" />
        <path d="M15.862 3.862c-3.38 0-6.138 2.759-6.138 6.138 0 3.38 2.759 6.138 6.138 6.138C19.242 16.138 22 13.379 22 10c0-3.38-2.759-6.138-6.138-6.138zm0 10.207A4.077 4.077 0 0111.793 10a4.077 4.077 0 014.07-4.069A4.077 4.077 0 0119.931 10a4.077 4.077 0 01-4.069 4.069z" />
      </g>
    </svg>
  )
}

export default ViewIcon