import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Button from 'components/Button';
import styles from './googleMap.module.scss';

const GoogleMap = ({ lat, lng, buttons }) => (
  <div className={styles.googleMap}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.GATSBY_G_MAPS_API_KEY }}
      defaultCenter={{ lat, lng }}
      defaultZoom={9}
    ></GoogleMapReact>

    {buttons &&
      buttons.map((button, index) => (
        <Button
          key={`googleMapButton${index}`}
          handleClick={() => _.isFunction(button.onClick) && button.onClick()}
          type="app-transparent-blue-button"
        >
          {button.title}
        </Button>
      ))}
  </div>
);

GoogleMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default GoogleMap;
