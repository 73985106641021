import * as React from "react"

function AdmitIcon(props) {
  return (
    <svg width={31} height={22} viewBox="0 0 31 22" {...props}>
      <path
        fill="#1C5BAE"
        stroke="#1C5BAE"
        strokeWidth={0.01}
        d="M30.166 6.933a.74.74 0 00.741-.742v-4.45A.741.741 0 0030.166 1H1.742A.742.742 0 001 1.742V6.19c0 .41.332.742.742.742a4.078 4.078 0 010 8.157.742.742 0 00-.742.741v4.427c0 .41.332.742.742.742h28.424a.74.74 0 00.741-.742V15.83a.74.74 0 00-.741-.741 4.079 4.079 0 110-8.157zm-5.562 4.078a5.561 5.561 0 004.82 5.506v2.996H2.484v-2.992a5.561 5.561 0 00-.001-11.012V2.505h26.941v3a5.562 5.562 0 00-4.813 5.506h-.007zm-3.44-3.608H10.736a.742.742 0 00-.742.742v5.732c0 .41.332.742.742.742H21.17a.74.74 0 00.741-.742V8.145a.742.742 0 00-.741-.742h-.008zm-.742 5.733h-8.944v-4.25h8.951l-.007 4.25z"
      />
    </svg>
  )
}

export default AdmitIcon