import React from 'react';
import { graphql } from 'gatsby';
import AuctionDetails from 'features/auctionDetails';

const Auctions = props => (
  <AuctionDetails auction={props.data.contentfulWebPageAuction} />
);

export default Auctions;

export const query = graphql`
  query auctions($id: String) {
    contentfulWebPageAuction(contentful_id: { eq: $id }) {
      title
      featureImage {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      vehicleImage {
        fluid(maxWidth: 1800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      auction {
        contentful_id
        name
        subEvents {
          ... on ContentfulSubEventAuction {
            contentful_id
            name
            startDate
            endDate
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulSubEventViewing {
            name
            startDate
            endDate
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulSubEventShuttles {
            id
            name
            startDate
            endDate
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
        description {
          childMarkdownRemark {
            html
          }
          description
        }
        eventAttendanceMode
        eventStatus
        offers {
          ... on ContentfulOffer {
            contentful_id
            name
            eligibleQuantity
            eligibleCustomerType {
              title
            }
            price
            acceptedPaymentMethod
            category
            availability
            priceCurrency
            includesObject
          }
          ... on ContentfulBuyersPremium {
            contentful_id
            name
            sys {
              contentType {
                sys {
                  contentful_id
                }
              }
            }
            description {
              description
            }
          }
        }
        location {
          contentful_id
          name
          description
          venueLocation {
            lat
            lon
          }
          mailingAddress {
            ... on ContentfulAddress {
              id
              streetAddress
              postalCode
              postOfficeBoxNumber
              contentful_id
              addressRegion
              addressLocality
              addressCountry
            }
          }
          address {
            ... on ContentfulAddress {
              id
              streetAddress
              postalCode
              postOfficeBoxNumber
              contentful_id
              addressRegion
              addressLocality
              addressCountry
            }
          }
        }
        conditionOfSale {
          contentful_id
          file {
            url
          }
        }
        organizer {
          name
          contactPoint {
            email
            faxNumber
            name
            telephone
          }
        }
        lot {
          item {
            ... on ContentfulVehicle {
              id
              salesForceId
            }
            ... on ContentfulAutomobilia {
              id
              salesForceId
            }
          }
        }

        webpage__auction {
          ctaList {
            buttonText
            style
            id
            action {
              ... on ContentfulWebRedirectAction {
                url
                openInANewTab
              }
              ... on ContentfulWebOpenFormAction {
                form
              }
              ... on ContentfulWebDownloadAction {
                file {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
