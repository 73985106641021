import React from 'react';
import { navigate } from '@reach/router';
import { ONLINE_ONLY_AUCTION } from 'src/utils/auctionTypes';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import DetailedCardWithBackground from 'components/DetailedCardWithBackground';
import GoogleMap from 'components/GoogleMap';
import ViewIcon from 'svg/ViewIcon';
import AdmitIcon from 'svg/AdmitIcon';
import BidderIcon from 'svg/BidderIcon';
import ImageBanner from 'components/ImageBanner';
import CarIcon from 'svg/CarIcon';
import ContactPhoneIcon from 'svg/ContactPhoneIcon';
import MailIcon from 'svg/MailIcon';
import PDFApplication from 'static/pdf/GCo_Bidder_Registration_Application_0719.pdf';
import HeroSection from 'components/HeroSection';
import TitleDescriptionCollapse from 'components/TitleDescriptionCollapse';
import AuctionInfo from 'components/AuctionInfo';
import styles from './auction.module.scss';
import {
  getAuctionEventDatesFlat,
  getCataloguePreFilteredByAuction,
  getEventDateAverage,
  getAuctionEventDates,
} from 'shared/helpers/auctions';
import moment from 'moment';
import { CONSIGNMENT_PATH } from 'utils/pathsConstants';

const Auctions = ({ auction }) => {
  const auctionData = auction.auction;
  const GOOGLE_MAP_URL = `http://maps.google.com/maps?q=${auctionData.location?.venueLocation?.lat},${auctionData.location?.venueLocation?.lon}`;

  const auctionType = auctionData?.eventAttendanceMode || '';
  const auctionEvents = getAuctionEventDatesFlat(auctionData);
  const auctionEventsFullData = getAuctionEventDates(auctionData);
  const oneLineAuctionEvents = getEventDateAverage(auctionEventsFullData);
  const auctionYear = moment(auctionEvents[0]).format('YYYY');
  const maxStartDate = Date.parse(auctionEvents[auctionEvents.length - 1]);
  const today = Date.parse(new Date());
  const constructOnlineCatalogueUrl = getCataloguePreFilteredByAuction(
    auctionData,
    auctionYear,
    today < maxStartDate
  );
  const getAcceptablePaymentMethods = offers => {
    let paymentMethods = new Set();
    offers.forEach(offer => {
      if (offer && offer.acceptedPaymentMethod) {
        offer.acceptedPaymentMethod.forEach(paymentMethod => {
          paymentMethods.add(paymentMethod);
        });
      }
    });
    return getPaymentMethod(paymentMethods);
  };

  const getPaymentMethod = paymentMethods => {
    const cash = paymentMethods.has('Cash');
    const creditCard = paymentMethods.has('Credit Card');
    let payments = '';
    if (cash) payments += 'Cash';
    if (creditCard) {
      if (cash) payments += ' or Credit Card';
      else payments += 'Credit Card';
    }

    return payments;
  };

  const getBidderRegistration = offers => {
    const bidderRegistration = [];
    offers
      .filter(offer => {
        return (
          offer.category === 'Bidder Registration' &&
          offer.eligibleQuantity === 1 &&
          offer.eligibleCustomerType.filter(customerType => {
            return customerType.title === 'Standard';
          }).length > 0
        );
      })
      .forEach(offer => {
        bidderRegistration.push({
          title: `${offer.price}
            ${offer.priceCurrency} includes 
            ${offer.includesObject?.includes('Catalogue') ? 'a catalogue' : ''},
            ${
              offer.includesObject?.includes('Single Admission')
                ? 'admission for one to the viewing and auction'
                : offer.includesObject?.includes('Double Admission')
                ? 'admission for two to the viewing and auction'
                : ''
            }
            ${
              offer.includesObject?.includes('Reserved Seating')
                ? offer.includesObject?.includes('Single Admission')
                  ? 'with one reserved seats, subject to availability'
                  : offer.includesObject?.includes('Double Admission')
                  ? 'with two reserved seats, subject to availability'
                  : ''
                : ''
            }
          `,
        });
      });
    return bidderRegistration;
  };

  // online auctions registration opens before the first subevent by week
  const getRegistrationOpenDate = auctionEvents => {
    const registerOpenDate = (
      moment(auctionEvents?.length > 0 && auctionEvents[0]) || moment()
    )
      .subtract(7, 'days')
      .format('MMMM Do');

    return registerOpenDate;
  };

  const constructAuctionInfo = auctionData => {
    const info = [];

    // Viewing Section
    if (
      auctionData.subEvents &&
      auctionData.subEvents.filter(
        event => event.sys.contentType.sys.id === 'subEventViewing'
      ).length > 0
    ) {
      const subEventViewing = [];
      auctionData.subEvents
        .filter(event => event.sys.contentType.sys.id === 'subEventViewing')
        .forEach(event => {
          subEventViewing.push({
            title: new Date(event.startDate).toLocaleString('en-US', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }),
            time: `
              ${new Date(event.startDate).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
              -
              ${new Date(event.endDate).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
              `,
          });
        });

      info.push({
        icon: <ViewIcon />,
        title: 'Viewing',
        info: subEventViewing,
      });
    }

    // Admittance Section
    if (
      auctionData.offers &&
      auctionData.offers.filter(
        offer =>
          offer.category === 'Admittance' || offer.category === 'Catalogue'
      ).length > 0
    ) {
      const Admittance = [];

      auctionData.offers
        .filter(offer => offer.category === 'Admittance')
        .forEach(offer => {
          Admittance.push({
            title: `Admittance - ${offer.price} ${offer.priceCurrency}, admits one to all events`,
          });
        });

      auctionData.offers
        .filter(offer => offer.category === 'Catalogue')
        .forEach(offer => {
          Admittance.push({
            title: `Catalogue - ${offer.price} ${offer.priceCurrency}, admits two to all events`,
          });
        });

      Admittance.push({
        title: getAcceptablePaymentMethods(auctionData.offers),
      });

      if (
        auctionData.offers.filter(
          offer => offer.category === 'Child Admittance'
        ).length > 0
      ) {
        Admittance.push({ title: 'Children under 12 free.' });
      }

      info.push({
        icon: <AdmitIcon />,
        title: 'Admittance',
        info: Admittance,
      });
    }

    // Bidder Registration
    if (
      auctionData.offers &&
      auctionData.offers.filter(
        offer => offer.category === 'Bidder Registration'
      ).length > 0
    ) {
      info.push({
        icon: <BidderIcon />,
        title: 'Bidder Registration',
        info: getBidderRegistration(auctionData.offers),
      });
    }

    // Bidder Registration online auctions
    if (auctionType === ONLINE_ONLY_AUCTION) {
      info.push({
        icon: <AdmitIcon />,
        title: 'Bidder Registration',
        info: [
          {
            title: `Online bidder registration will open on ${getRegistrationOpenDate(
              auctionEvents
            )} and will be available through our website and mobile apps. The full Conditions of Sale will be posted at that time.`,
          },
        ],
      });
    }

    // Buyer's Premium
    if (
      auctionData.offers &&
      auctionData.offers.filter(offer => 'sys' in offer).length > 0
    ) {
      const buyersPremium = [];
      auctionData.offers
        .filter(offer => 'sys' in offer)
        .filter(
          offer =>
            offer.sys.contentType.sys.contentful_id === 'priceSpecification'
        )
        .forEach(offer => {
          if (offer.description) {
            buyersPremium.push({ title: offer.description.description });
          }
        });

      info.push({
        title: 'Buyer’s Premium:',
        icon: <CarIcon />,
        info: buyersPremium,
      });
    }

    // On Site Contact
    if (auctionData.organizer && auctionData.organizer.contactPoint) {
      info.push({
        icon: <ContactPhoneIcon />,
        title:
          auctionType === ONLINE_ONLY_AUCTION
            ? 'Contact Information'
            : 'On Site Contact',
        info: [
          {
            type: 'tel',
            title: auctionData.organizer.contactPoint.telephone
              ? `Telephone: `
              : '',
            tel: auctionData.organizer.contactPoint.telephone || '',
          },
          {
            type: 'tel',
            title: auctionData.organizer.contactPoint.faxNumber ? `Fax: ` : '',
            tel: auctionData.organizer.contactPoint.faxNumber || '',
          },
          {
            title: auctionData.organizer.contactPoint.email
              ? `Email: ${auctionData.organizer.contactPoint.email}`
              : '',
          },
          {
            title:
              auctionType === ONLINE_ONLY_AUCTION ? (
                <div className="mt-4">
                  <p>Gooding &amp; Company</p>
                  <p className="p-0">1517 20th Street</p>
                  <p>Santa Monica, CA 90404</p>
                </div>
              ) : (
                ''
              ),
          },
        ],
      });
    }

    // Mailing Address
    if (
      auctionData.location &&
      auctionData.organizer &&
      auctionData.location.mailingAddress
    ) {
      info.push({
        icon: <MailIcon />,
        title: 'Mailing Address',
        info: [
          {
            title: auctionData.organizer.name,
          },
          {
            title: auctionData.location.mailingAddress.streetAddress,
          },
          {
            title: `${auctionData.location.mailingAddress.addressLocality} ${auctionData.location.mailingAddress.addressRegion} ${auctionData.location.mailingAddress.postalCode}`,
          },
        ],
      });
    }

    return info;
  };

  const getShowVehiclesButton = (auctionData, title) => {
    let button = null;
    if (auctionData.lot?.length > 0) {
      auctionData.lot.every(lot => {
        if (lot.item?.id) {
          button = {
            title: title,
            path: constructOnlineCatalogueUrl,
          };
          return false;
        } else return true;
      });
    }
    return button;
  };

  const showVehiclesButton = getShowVehiclesButton(auctionData, '');

  const conditionOfSaleButton = auctionData?.conditionOfSale?.file?.url
    ? [
        {
          title: 'Conditions of Sale',
          onClick: () => window.open(auctionData.conditionOfSale.file.url),
        },
      ]
    : [];

  return (
    <Layout transparentHeader={true} paddingTop={false}>
      <SEO title={auctionData.name} />
      <HeroSection
        key={`auctionsHeroSection`}
        image={auction.featureImage}
        title={auctionData.name}
        titleWithoutMargin={true}
        description={`<p class="titleLg">${oneLineAuctionEvents}</p>`}
        callToActions={auctionData?.webpage__auction?.[0]?.ctaList}
      />
      <TitleDescriptionCollapse
        title={'The Auction'}
        description={auctionData.description.childMarkdownRemark.html}
        isMarkdown={true}
        subTitles={[
          [
            {
              title: new Date(auctionEvents[0]).toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }),
              bold: true,
            },
            {
              title: new Date(
                auctionEvents[auctionEvents.length - 1]
              ).toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }),
              bold: true,
            },
          ],
          [
            { title: auctionData.location?.description },
            { title: auctionData.location?.address?.streetAddress || '' },
            {
              title: `${auctionData.location?.address?.addressLocality || ''} 
                        ${auctionData.location?.address?.addressRegion || ''} 
                        ${auctionData.location?.address?.addressCountry || ''}`,
            },
          ],
        ]}
        topButtons={
          auctionType === ONLINE_ONLY_AUCTION
            ? [
                {
                  title: 'Online Bidding FAQ',
                  onClick: () => navigate('/faq'),
                },
                ...conditionOfSaleButton,
              ]
            : [...conditionOfSaleButton]
        }
      />
      <div className={styles.infoWrapper}>
        <AuctionInfo
          className={auctionType === ONLINE_ONLY_AUCTION ? 'onlineAuction' : ''}
          auctionInfo={constructAuctionInfo(auctionData)}
        />
        {auctionType !== ONLINE_ONLY_AUCTION &&
          (auctionData?.location?.venueLocation?.lat &&
          auctionData?.location?.venueLocation?.lon ? (
            <GoogleMap
              lat={auctionData.location?.venueLocation?.lat}
              lng={auctionData.location?.venueLocation?.lon}
              buttons={[
                {
                  title: 'Get Directions',
                  onClick: () => window.open(GOOGLE_MAP_URL),
                },
                {
                  title: 'Conditions of Sale',
                  onClick: () =>
                    window.open('https://www.goodingco.com/terms/'),
                },
              ]}
            />
          ) : (
            <></>
          ))}
      </div>
      <ImageBanner
        fluidImage={auction.vehicleImage?.fluid}
        title={`${auctionData.name} Vehicles`}
        buttons={
          showVehiclesButton ? [getShowVehiclesButton(auctionData, 'View')] : []
        }
      />
      <DetailedCardWithBackground
        position="center"
        cards={[
          {
            title: 'Now Inviting Consignments',
            buttons: [
              {
                title: 'Submit Your Vehicle Online',
                onClick: () => navigate(CONSIGNMENT_PATH),
              },
            ],
            footer: {
              title: 'Or, Download PDF Form',
              onClick: () => window.open(PDFApplication),
            },
          },
        ]}
      />
    </Layout>
  );
};

export default Auctions;
